<template>
  <div>
    <!--    <v-progress-linear-->
    <!--      :active="loading"-->
    <!--      :indeterminate="loading"-->
    <!--      class="my-3"-->
    <!--    />-->
    <v-card v-if="!elevator.audit">
      <v-card-text class="d-flex flex-column align-center justify-center py-5">
        <div>Pas encore d'audit</div>
        <v-btn
          v-if="$store.state.auth.user.isAdmin"
          color="primary"
          dark
          class="mt-3"
          @click="
            $router.push({ name: 'CreateAudit', params: { id: elevator.id } })
          "
        >
          Créer un audit
        </v-btn>
      </v-card-text>
    </v-card>
    <div v-if="audit">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7" class="pt-0 d-flex align-center">
              <div>
                <div class="text-h5 font-weight-regular mb-2 mt-3">
                  <v-icon size="1.5em" class="ml-3 mr-5"
                    >mdi-clipboard-text</v-icon
                  >
                  Audit
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="5"
              class="pt-0 d-flex align-center justify-end"
            >
              <v-btn
                :loading="finishLoading"
                @click="endEditAudit(audit)"
                v-if="audit.editable"
                color="primary"
                class="mr-3"
              >
                <v-icon left>mdi-check</v-icon>
                Finaliser
              </v-btn>
              <v-btn
                @click="editAudit(audit)"
                v-if="audit.editable"
                color="primary"
                class="mr-3"
                icon
                outlined
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                color="secondary"
                class="mr-3"
                icon
                outlined
                @click="fetchData"
              >
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                :disabled="!audit.editable"
                v-if="$store.state.auth.user.isAdmin"
                color="error"
                icon
                outlined
                @click="$refs.removeForm.open(audit)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                class="ml-3"
                :disabled="!audit.document.key"
                @click="openDocumentExternal"
              >
                <v-icon left>mdi-open-in-new</v-icon>
                Télécharger
              </v-btn>
            </v-col>
          </v-row>

          <div class="mt-2">
            <v-list dense>
              <v-divider />
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-clock</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ audit.createdAt | formatDate }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Date de création
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider />

              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-weight</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ audit.totalMass }} kg
                  </v-list-item-title>
                  <v-list-item-subtitle> Masse totale </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider />

              <!--              <div-->
              <!--                v-for="summary in audit['summary']"-->
              <!--              >-->
              <!--                <div>-->

              <!--                </div>-->
              <!--                <div v-if="summary.status === 'TAKEN_BY_SOCOBAT'">-->
              <!--                  Pris par Socobat-->
              <!--                </div>-->
              <!--                <div v-if="summary.status === 'TAKEN_BY_ASCENSORIST'">-->
              <!--                  Pris par l'ascensoriste-->
              <!--                </div>-->
              <!--                <v-divider />-->
              <!--                <template v-for="type in summary['summary']">-->
              <!--                  <v-list-item :key="type.id">-->
              <!--                    <v-list-item-avatar>-->
              <!--                    </v-list-item-avatar>-->
              <!--                    <v-list-item-content>-->
              <!--                      <v-list-item-title>-->
              <!--                        {{ type.name }}-->
              <!--                      </v-list-item-title>-->
              <!--                      <v-list-item-subtitle>-->
              <!--                        <span v-for="waste in type.wastes">{{ waste.name }}, </span>-->
              <!--                      </v-list-item-subtitle>-->
              <!--                    </v-list-item-content>-->
              <!--                  </v-list-item>-->
              <!--                  <v-divider :key="type.id + '_d'" />-->
              <!--                </template>-->
              <!--              </div>-->

              <v-treeview
                class="mt-4"
                activatable
                :open="openedItems"
                open-on-click
                :items="items"
              >
                <template v-slot:append="{ item }">
                  {{ item.mass | formatNumber }} kg
                </template>
              </v-treeview>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
      <v-alert
        border="left"
        type="error"
        class="mt-3"
        v-if="!documentMightBeAvailable && !audit.document.key"
      >
        Le document n'est pas encore disponible. Veuillez reessayer plus tard.
      </v-alert>
      <div
        v-if="documentMightBeAvailable && !audit.document.key"
        class="d-flex justify-center align-center flex-column"
      >
        <v-progress-circular size="50" color="primary" indeterminate />
        <div class="mt-5 mb-5">
          Veuillez patientez pendant que nous générons le document...
        </div>
      </div>
      <RemoveItem
        resource="audits"
        title="cet audit"
        ref="removeForm"
        @finished="
          $router.push({
            name: 'ViewSite',
            params: { id: audit.elevator.site.id },
          })
        "
      />
    </div>
  </div>
</template>

<script>
import RemoveItem from "../../components/RemoveItem.vue";

import { DateTime } from "luxon";

export default {
  components: {
    RemoveItem,
  },
  props: {
    elevator: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    openedItems: [
      "TAKEN_BY_SOCOBAT",
      "TAKEN_BY_ASCENSORIST",
      "TAKEN_BY_SOCOBAT_AND_REVALORIZED",
    ],
    loading: false,
    audit: null,
    sse: null,
    finishLoading: false,
    documentMightBeAvailable: false,
    items: [],
  }),
  mounted() {
    this.fetchData();
  },
  filters: {
    formatNumber(number) {
      return number.toLocaleString("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      if (!this.elevator.audit || !this.elevator.audit.id) {
        return;
      }
      const url = new URL(process.env.VUE_APP_MERCURE_HUB);
      url.searchParams.append(
        "topic",
        process.env.VUE_APP_MERCURE_TOPIC + "/audits/" + this.elevator.audit.id
      );

      let sse = new EventSource(url);
      sse.onmessage = ({ data }) => {
        const update = JSON.parse(data);
        console.debug("received audit update", update);
        if (update.document && update.document.key) {
          this.audit.document.key = update.document.key;
          this.audit.document.url = update.document.url;
          this.documentMightBeAvailable = true;
          console.debug("got key", update.document.key);
          this.$store.commit("alert/add", {
            type: "success",
            message: "Le document vient d'être généré.",
          });
          this.$forceUpdate();
        }
      };

      this.$store
        .dispatch("audits/fetchDetails", this.elevator.audit.id)
        .then((res) => {
          console.log("Audit : ", res);
          this.audit = res;
          console.debug("Got audit", this.audit);
          // documentMightBeAvailable if created at is less than 5 minutes
          const diffInMinutes = Math.abs(
            DateTime.fromISO(this.audit.createdAt).diffNow().as("minutes")
          );
          console.debug("Creation diff in minutes:", diffInMinutes);
          this.documentMightBeAvailable = diffInMinutes < 5;

          this.items = this.audit["summary"].map((category) => {
            let name = "";
            switch (category.status) {
              case "TAKEN_BY_SOCOBAT":
                name = "Déchets à évacuer";
                break;
              case "TAKEN_BY_ASCENSORIST":
                name = "Pris par l'ascensoriste";
                break;
              case "TAKEN_BY_SOCOBAT_AND_REVALORIZED":
                name = "Déchets a réemployer";
                break;
            }
            //name += ` (${formatNumber(category.totalMass)}) kg`
            return {
              id: category.status,
              name,
              mass: category.totalMass,
              children: category["summary"].map((type) => {
                return {
                  id: type.id,
                  name: type.name,
                  mass: type.totalMass,
                  children: type["wastes"].map((waste) => ({
                    id: waste.id,
                    name: waste.name,
                    mass: waste.mass,
                  })),
                };
              }),
            };
          });

          console.debug("Audit url", this.audit.document.url);
        })
        .catch((err) => {
          console.error("Error fetching audit", err);
          this.$store.commit("alert/add", {
            type: "error",
            message: "Impossible de récupérer l'audit.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editAudit(audit) {
      this.$router.push(`/elevator/${this.elevator.id}/audit/edit/${audit.id}`);
    },
    endEditAudit(audit) {
      this.finishLoading = false;
      this.$store.dispatch("audits/endEditable", audit.id).then((res) => {
        console.log("res : ", res);
        this.finishLoading = true;
        window.location.reload();
      });
    },
    openDocumentExternal() {
      // open the audit document in new tab
      window.open(this.audit.document.url, "_blank");
    },
  },
};
</script>
